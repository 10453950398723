<template>
  <div class="history-container">
    <CCard>
      <CCardHeader>
        <h5>{{$t('LOGIN_HISTORY_COMPONENT_TITLE_HISTORY')}}</h5>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="12">
            <!-- <Tables :items="items" :fields="fields" hover striped bordered /> -->
            <CDataTable
              class="mb-0"
              hover
              outlined
              striped
              bordered
              :items="items"
              :fields="fields"
              head-color="light"
              no-sorting
            >
              <td slot="order" slot-scope="{item, index}">{{index + 1}}</td>
              <td
                slot="updatedAt"
                slot-scope="{item}"
              >{{item.updatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
            </CDataTable>
            <div>
              <CPagination
                v-if="pages>1" 
                :activePage.sync="activePage"
                :pages="pages"
                align="end"
                @update:activePage="pushPage"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Tables from '@/components/ChildPool/Tables';
import endpoints from '@/constants/endpoints';
export default {
  components: {
    // Tables
  },
  data() {
    return {
      fields: [
        {
          key: 'order',
          label: '#'
        },
        {
          key: 'updatedAt',
          label: this.$t('LOGIN_HISTORY_COMPONENT_LABEL_LOGIN_AT')
        },
        {
          key: 'client_ip',
          label: this.$t('LOGIN_HISTORY_COMPONENT_LABEL_CLIENT_IP'),
          _style: 'width:15%'
        },
        {
          key: 'user_agent',
          label: this.$t('LOGIN_HISTORY_COMPONENT_LABEL_USER_AGENT'),
          _style: 'width:60%'
        }
      ],
      items: [],
      activePage: 1,
      limit: 5,
      pages: 0
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    }
  },
  async mounted() {
    const data = await this.getHistory();
    if (!data) {
      return;
    }
    console.log(data);

    this.items = data.items.slice();
    this.pages = Math.ceil(parseInt(data.total) / this.limit);
  },
  methods: {
    async getHistory() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };
        const res = await this.$http.get(endpoints.getLoginHistory, {
          params: params
        });

        return res.data;
      } catch (err) {
        console.log(err);
      }
    },
    async pushPage() {
      const items = await this.getHistory();
      this.items = items.items.slice();
    }
  }
};
</script>
<style lang="scss">
  .history-container{
    padding: 0 10px;
    table th,
    table td{
      padding: 8px !important;
      vertical-align: middle !important;
    }
  }
</style>
